import * as React from "react"
import { Box, Typography } from '@mui/material';
import Layout from "components/Layout"
import Seo from "components/seo"

const NotFoundPage = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
    }}
  >
    <Typography variant="h1">404: Not Found</Typography>
    <Typography><p>You just hit a route that doesn&#39;t exist... the sadness.</p></Typography>
  </Box>
);

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage;
